//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ProductCard from '@/components/product/card'
import productHolder from '@/components/product/card/productEmpty'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'

export default {
  components: {
    ProductCard,
    productHolder,
    Swiper,
    SwiperSlide,
  },

  directives: {
    swiper: directive,
  },

  props: {
    title: {
      type: String,
      default: '',
    },
    slides: {
      type: [Array, Object],
      default() {
        return {}
      },
    },
  },

  computed: {
    options() {
      return {
        threshold: 10,
        resizeObserver: true,
        slidesPerView: 1.5,
        spaceBetween: 0,
        scrollbar: {
          draggable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        wrapperClass: 'swiper-wrapper',
        breakpoints: {
          576: {
            slidesPerView: 3.5,
          },
          992: {
            slidesPerView: 4.5,
          },
          1200: {
            slidesPerView: 5,
          },
        },
      }
    },

    group_slides: {
      get() {
        return this.slides.length ? this.slides : 5
      },
      set() {
        this.slides.length ? this.slides : 5
      },
    },
  },
}
