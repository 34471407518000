//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CurrencyMixin from '@/mixins/currency'
import WishlistMixin from '@/mixins/wish.js'

export default {
  mixins: [CurrencyMixin, WishlistMixin],
  props: {
    data: {
      type: Object,
      default() {
        return {
          id: {
            type: Number,
            default: 0,
          },
          name: {
            type: String,
            default: '',
          },
          price: {
            type: Number,
            default: 0,
          },
          thumbnail: {
            type: String,
            default: '',
          },
          category: {
            type: String,
            default: '',
          },
        }
      },
    },
  },
  computed: {
    strippedHtml() {
      let regex = /(<([^>]+)>)/gi

      return this.data.content.replace(regex, '')
    },
  },
}
