//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  props: {
    data: {
      type: [Array, Number],
      default() {
        return []
      },
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      swiper_categoires_options: {
        slidesPerView: 1.5,
        slidesPerColumn: 2,
        spaceBetween: 0,
        resizeObserver: true,
        scrollbar: {
          draggable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        wrapperClass: 'swiper-wrapper',
        breakpoints: {
          768: {
            slidesPerView: 3,
            slidesPerColumn: 2,
            spaceBetween: 0,
          },
          1024: {
            slidesPerView: 5,
            slidesPerColumn: 1,
            spaceBetween: 15,
          },
        },
      },
    }
  },

  computed: {
    slides: {
      get() {
        return this.data.length ? this.data : 6
      },
      set() {
        this.data.length ? this.data : 6
      },
    },
    slidesTop: {
      get() {
        return this.data.length ? this.data.slice(0, 5) : 5
      },
      set() {
        this.data.length ? this.data.slice(0, 5) : 5
      },
    },
  },
}
