import { mapState, mapActions } from 'vuex'
export default {
  computed: {
    ...mapState('product/wishlist', ['wish_lists', 'wish_id_list']),
    is_hearted: {
      get() {
        return this.wish_id_list.includes(this.data.id) ? true : false
      },
      set() {
        this.wish_id_list.includes(this.data.id) ? true : false
      },
    },
    wish_list_id: {
      get() {
        const wish_item = this.wish_lists.find((item) => {
          return item.product_id == this.data.id ? item.id : null
        })
        return wish_item ? wish_item.id : null
      },
      set() {
        const wish_item = this.wish_lists.find((item) => {
          return item.product_id == this.data.id ? item.id : null
        })
        return wish_item ? wish_item.id : null
      },
    },
  },

  methods: {
    ...mapActions({
      addToWishList: 'product/wishlist/store',
      deleteFromWishList: 'product/wishlist/destroy',
    }),
    handleToggleWishList() {
      this.is_hearted = !this.is_hearted
      if (this.$store.state.auth.loggedIn) {
        const is_fav = !this.is_hearted

        if (is_fav) {
          this.addToWishList({ id: this.data.id })
        } else {
          this.deleteFromWishList({
            id: this.wish_list_id,
            product_id: this.data.id,
          })
        }
        return
      }
      this.$toast.error(this.$t('toast.no_logged_in'))
      this.$bvModal.show('Login') // show AuthModal
    },
  },
}
